import React from "react";

const defaultState = {
  dark: true,
  toggleDark: () => {},
};

const ThemeContext = React.createContext(defaultState);

class ThemeProvider extends React.Component {
  state = {
    dark: true,
  };

  toggleDark = () => {
    const html = document.querySelector("html");
    let dark = !this.state.dark;
    localStorage.setItem("dark", JSON.stringify(dark));
    this.setState({ dark });
    if (dark === false) {
      html.classList.remove("ud-dark");
    } else {
      html.classList.add("ud-dark");
    }
  };

  componentDidMount() {
    // Getting dark mode value from localStorage!
    const html = document.querySelector("html");

    const lsDark = JSON.parse(localStorage.getItem("dark"));

    // console.log("lsDARK", lsDark);
    if (lsDark === true) {
      this.setState({ dark: lsDark });
      html.classList.add("ud-dark");
    } else if (lsDark === null) {
      this.setState({ dark: true });
      localStorage.setItem("dark", JSON.stringify(this.state.dark));
      html.classList.add("ud-dark");
    } else if (lsDark === false) {
      this.setState({ dark: lsDark });
      html.classList.remove("ud-dark");
    }
  }

  render() {
    const { children } = this.props;
    const { dark } = this.state;
    return (
      <ThemeContext.Provider
        value={{
          dark,
          toggleDark: this.toggleDark,
        }}
      >
        {children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContext;

export { ThemeProvider };
